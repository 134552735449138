import request from '../utils/request.js';
let $domain = "https://print.ynlothar.com/api";
let $domain2 = "https://womanatd.ynlothar.com"
let $scanning = "http://127.0.0.1:38088";

export default {
	getAdsList: (data) => request.get($domain + '/home/getAdsList', data),
	//首页纸张
	PcHome: (data) => request.get($domain + '/home/PcHome', data),
	//订单列表
	TaskList: (data) => request.get($domain + '/my/myOrder', data),
	//订单删除
	orderDel: (data) => request.post($domain + '/my/orderDel', data),

	//创建任务
	saveTask: (data) => request.post($domain + '/print_er/saveTask', data),
	//余额
	balance: (data) => request.get($domain + '/my/balance', data),
	//充值列表
	PayList: (data) => request.get($domain + '/my/PayList', data),
	//会员充值
	scanBalancePay: (data) => request.post($domain + '/buy/scanBalancePay', data),

	//商品分类
	shopCateList: (data) => request.get($domain + '/home/shopCateList', data),
	//无限分类
	shopCateChildList: (data) => request.get($domain + '/home/shopCateChildList', data),

	//商品列表
	shopList: (data) => request.get($domain + '/shop/shopList', data),
	//游客扫码支付
	ykscanPay: (data) => request.post($domain + '/Scan_pay/scanPay', data),
	//会员扫码支付
	scanPay: (data) => request.post($domain + '/buy/scanPay', data),
	//支付是否成功
	scanPaySuccessful: (data) => request.get($domain + '/buy/scanPaySuccessful', data),
	//游客支付是否成功
	ykscanPaySuccessful: (data) => request.get($domain + '/Scan_pay/scanPaySuccessful', data),
	//游客计算价格
	yksumMoney: (data) => request.post($domain + '/home/sumMoney', data),
	//会员计算价格
	sumMoney: (data) => request.post($domain + '/my/sumMoney', data),
	//再次打印
	again_print: (data) => request.post($domain + '/print_er/again_print', data),

	//会员上传文件
	memUpload: (data) => request.post($domain + '/file/index', data),
	//游客上传文件
	uploadFile: (data) => request.post($domain + '/home/uploadFile', data, false),

	//文件上传列表
	fileList: (data) => request.get($domain + '/file/fileList', data),
	//删除文件 
	fileDel: (data) => request.post($domain + '/file/fileDel', data),

	//base64转pdf
	base64Pdf: (data) => request.post($domain + '/home/base64Pdf', data),
	//上传扫描文件路径
	fileSave: (data) => request.post($domain + '/file/fileSave', data),

	//扫描图片保存 
	pngSave: (data) => request.post($domain + '/file/pngSave', data),

	//处理图片黑白 
	blackImg: (data) => request.get($domain2 + '/jeecgBootApi/jeecg-boot/javaApi/pdf/blackImg', data),
	
	//pdf处理黑白
	blackPDF: (data) => request.get($domain2 + '/jeecgBootApi/jeecg-boot/javaApi/pdf/blackPDF?', data),
	

	//余额支付  /api/buy/balancePayment
	balancePayment: (data) => request.post($domain + '/buy/balancePayment', data),

	//订单余额支付  /api/buy/balancePayment
	orderPay: (data) => request.post($domain + '/buy/orderPay', data),

	//打印成功
	accomplish: (data) => request.post($domain + '/print_er/accomplish', data),

	//个人信息
	getUserInfo: (data) => request.post($domain + '/my/index', data),
	//订单付款


	//查询订单详情
	OrderDetail: (data) => request.get($domain + '/home/OrderDetail', data),

	//生成小程序二维码
	weChatCode: (data) => request.post($domain + '/home/weChatCode', data),

	//账号列表
	AccountList: (data) => request.get($domain + '/Enterprise/AccountList', data),


	//高拍仪上传
	base64Idcard: (data) => request.post($domain + '/home/base64Idcard', data),
	
	
	//广告
	getAdsList: (data) => request.get($domain + '/home/getAdsList', data),




   //轮训打印状态
   getTaskStatus: (data) => request.get($domain + '/print_call_back/getTaskStatus', data),


	//拍照
	// scanning:(data) =>request.post($scanning+'/api/video=grabimage',data)



}
