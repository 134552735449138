import Vue from 'vue'
import Vuex from 'vuex'

import {
		getLodop
	} from '../utils/LodopFuncs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  webSocketMsg:"",
	  userinfo:JSON.parse(sessionStorage.getItem('userinfo')),
	  devList:{}
  },
  mutations: {
	  
	  
	  
	  
	  
	  setUserinfo(state,step){
		  sessionStorage.setItem('userinfo',JSON.stringify(step));
		  
		  state.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
		 
	  },
	  setDevList(state,LODOP){
		
		  var count = LODOP.GET_PRINTER_COUNT();

		  for(var i = 0; i<count; i++){
		  	 console.log('设备名称STORE',LODOP.GET_PRINTER_NAME(i));
		  	 var devname = LODOP.GET_PRINTER_NAME(i);
		  	 state.devList[devname] = i;
		  }
		  
	  }
	  
  },
  actions: {
  },
  modules: {
  }
})
