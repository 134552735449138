<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {


	}
</script>

<style lang="scss">
	/* 全局样式表 */
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	html,
	body,
	#app {
		height: 100%;
		margin: 0;
		padding: 0;
		min-width: 1366px;

	}

	.container {
		width: 1300px;
		margin: 0 auto;
	}


</style>
