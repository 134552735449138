<template>
	<div class="time">
         {{ time  }}  &nbsp;&nbsp;&nbsp;&nbsp; {{ day }}
	</div>
</template>

<script>
	export default {
		data() {
			return {
				time:"",
				day:""
			}
			
		},
		created(){
			let time = setInterval(()=>{
				this.getCurrentTime()
			},1000)
			
			this.getDay();
		},
		methods: {
			getCurrentTime() {
				//获取当前时间并打印
				var _this = this;
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth() + 1;
				let dd = new Date().getDate();
				let hh = new Date().getHours();
				let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
				let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
				this.time = yy + '年' + mm + '月' + dd + '日  ' + hh + ':' + mf + ':' + ss;
			},
			getDay(){
				this.day = "星期" + "日一二三四五六".charAt(new Date().getDay());
				
			}
		}
	}
</script>

<style scode>
	.time{
		color:#fff;
	}
	
</style>
