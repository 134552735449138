<template>
	<div>

		<!-- 	<el-button @click="FilePages()">纸张页码</el-button> -->


		<!-- 	<el-button @click="handleZj()">证件照</el-button>

		<el-button @click="handleFile()">文件打印1</el-button>

		<el-button @click="handlePic()">图片打印</el-button>

		<el-button @click="handleExcel()">excle打印</el-button>

		<el-button @click="test()">测试一下</el-button>

 -->


<!-- 		<el-button @click="test()">测试一下</el-button>
 -->
		<div class="paiban" style='position: absolute; left:-1000px' v-if="pic">
			<div id="box1" class="photobox">
				<div class="photo"
					style="width:20.4cm; height:30.4cm; display: block; background-color: #fff; padding:0.4cm 0 0 0.2cm"
					id="html2canvas1" ref="html2canvas1">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.1cm solid transparent">
				</div>
			</div>

			<br>

			<div id="box2" class="photobox" style="">
				<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center; background-color: #fff;"
					id="html2canvas2" ref="html2canvas2">
					<div class="box" style="width:18cm; margin:0 auto">

						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">



					</div>
				</div>
			</div>

			<br>

			<div id="box3" class="photobox" style="">
				<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center; background-color: #fff;  "
					id="html2canvas3" ref="html2canvas3">
					<div class="box" style="margin:0 auto">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
					</div>
				</div>
			</div>


			<br>

			<div id="box4" class="photobox" style="">
				<div class="photo" style="width:20.4cm; height:30.4cm;  text-align: center; background-color: #fff;"
					id="html2canvas4" ref="html2canvas4">
					<div class="box" style="width:18cm; margin:0 auto">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
					</div>
				</div>
			</div>



			<br>

		</div>





		<el-dialog :visible.sync="printOk" width="30%">
			<div class="info" style="text-align: center;">
				<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
				<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>

				<div v-if="$store.state.userinfo">
					<p style="color:red; font-weight: bold; font-size: 16px; margin-bottom: 20px;">为确保您的信息安全，打印完成后记得退出
					</p>
					<p>
						<el-button type="danger" style="width:80%" @click="loginout()">退出</el-button>
					</p>
					<p>
						<el-button type="primary" style="width:80%; margin:30px 0" @click="printOk=false">继续打印
						</el-button>
					</p>
				</div>
			</div>
		</el-dialog>

	</div>
</template>





<script>
	import html2canvas from 'html2canvas'


	const {
		GetPrintWorld,
		ToAbsoluteURL
	} = require('../utils/print/PrintWorld')
	export default {

		data() {
			return {
				//pic: require('@/assets/images/face.png'),
				//sfz: require('@/assets/photo/sfz.jpg'),
				sfz1: require('@/assets/photo/aa.jpg'),
				sfz2: require('@/assets/photo/bb.jpg'),
				pic: "",
				poster: null,
				model: "0",
				task_number: '',
				printOk: false,
				num: 0,
				timer: null,
				loading: false,
				type: "printfile",
				//	type:"previewfile",
				device: ''
			}
		},
		created() {
			this.model = localStorage.getItem('model'); //1 a4打印机   2 a3打印机

			this.device = localStorage.getItem('device');



		},
		methods: {

			test() {


				var printWorld = GetPrintWorld();
				let json = {
					//action: "previewfile", //预览打印
					action: "filepages", //预览打印
					copies: 1, //数量
					colorful: 1, // 颜色
					content: "https://print.ynlothar.com/uploads/20220223/ceshi.pdf", //内容
					duplex: 1, //1单面打印 2双面打印
					html: "https://print.ynlothar.com/mypreview/mypreview.htm"
				}

				json.printer = "EPSON WF-C5290 Series";
			//	json.printer = "Canon MG2500 series Printer";
				json.format = "pdf_url";
				console.log('json', json)
				printWorld.Act(json)


			},
			
			
			//退出登录
			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.printOk = false;
				this.$router.push('/');
			},
			

			order(data, type) {
				this.type = type;
			//	this.type = "previewfile"
				console.log('订单详情-子组件', data)
				this.task_number = data.task_number;
			


				if (this.type == "printfile") {

					this.loading = this.$loading({
						lock: true,
						text: '创建打印任务',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});

				}



				if (data.task_type == 1 || data.task_type == 6 || data.task_type == 7) { //文档打印

					let file_type = data.print_file.split('.')[1];
					if (data.paper_type == "3") { //去底色
						data.print_file = data.print_hb_qds_file;
						data.paper_type = 1; //去底色
					}



					let obj = {
						print_type: data.print_type, //a4
						//type: "previewfile",
						type: this.type,
						print_number: data.print_number, //数量
						print_ctype: data.print_ctype, //1单面  2双面
						file_type: file_type, //文件类型
						print_file: this.$domain + data.print_file, //文件地址
						paper_type: data.paper_type, //1黑白 2彩色
						print_typeset: data.print_typeset //1竖版  2横版
					}
                   console.log(123,obj)
                 
					this.printFile(obj)


				}





				if (data.task_type == 2 || data.task_type == 5 || data.task_type == 8) { //图片打印


					let obj = {
						print_type: data.print_type, //a4
						//type: "previewfile",
						type: this.type,
						print_number: data.print_number, //数量
						print_file: data.print_file, //文件地址
						paper_type: data.paper_type, //1黑白 2彩色

					}


					console.log('图片', data.print_file)


					this.printPic(obj)

				}




				if (data.task_type == 3) { //证件照打印


					let obj = {
						print_type: data.print_type, //4一寸
						type: this.type, //预览
						print_number: data.print_number //数量
					}


					this.pic = this.$domain + data.print_file + '?' + new Date().getTime();
					console.log('pic', this.pic)

					this.$nextTick(() => {
						this.printZj(obj)
					})



				}

				if (this.type == "printfile") {
					this.cycle();
				}


			},



			FilePages(url) {
				var printWorld = GetPrintWorld();

				var json = {};
				json.action = "filepages";
				json.format = "pdf_url"; // excel文件 url；word为word_url；ppt为ppt_url；PDF为pdf_url
				json.content = "https://print.ynlothar.com/uploads/20220223/ceshi.pdf";
				json.papersize = 9; //9，即为a4
				//json.orientation = 2;


				json.printer = "MG2500 series";
				printWorld.Act(json)
			},




			//处理excel
			handleExcel() {
				let obj = {
					print_type: 2, //a4
					type: "previewfile",
					//type: "printfile",
					print_number: 1, //数量
					print_ctype: '1', //单面
					file_type: 'xlsx', //文件类型
					//print_file: 'https://print.ynlothar.com/uploads/20220223/ceshi.docx', //文件地址
					print_file: 'https://print.ynlothar.com/uploads/20220527/d80653403bffa4c30b6b6a00a533b48729148285.xlsx', //文件地址
					paper_type: '1', //文件颜色
					print_typeset: 1 //横版竖版
				}

				this.printFile(obj)
			},



			//处理证件
			handleZj() {

				let obj = {
					print_type: 3, //4一寸
					//type: "previewfile", //预览
					type: "printfile",
					print_number: 1 //数量
				}

				this.printZj(obj)
			},


			//处理文件
			handleFile() {
				let obj = {
					print_type: 2, //a4
					//type: "previewfile",
					type: "printfile",
					print_number: 1, //数量
					print_ctype: '1', //单面
					file_type: 'pdf', //文件类型
					//print_file: 'https://print.ynlothar.com/uploads/20220223/ceshi.docx', //文件地址
					print_file: 'https://print.ynlothar.com/uploads/20220223/ceshi.pdf', //文件地址
					paper_type: '1', //文件颜色
					print_typeset: 2 //横版竖版
				}

				this.printFile(obj)
			},



			handlePic() {

				let obj = {
					print_type: 7, //6照片
					type: "previewfile",
					//type: "printfile",
					print_number: 1, //数量	
					print_file: 'https://print.ynlothar.com/uploads/20220524/40bd182fe4545647b1d833a4e63812f7346359b3.png', //文件地址
					//	print_file: 'https://print.ynlothar.com/uploads/20220526/68e7d13c18df69ff6ccdcd15a97c9e9807cf58f8.png',
					//    print_file:' https://print.ynlothar.com/uploads/20220526/8f53ec6548cf802798bb84b4a6cf8f7ae82bc81f.png',  //身份证
					paper_type: '2', //文件颜色
				}

				this.printPic(obj)


			},


			cycle() {

				this.timer = setInterval(() => {
					this.num++;
					if (this.num >= 30) {
						clearTimeout(this.timer);

						this.loading.close();
						this.$message.error('打印任务创建失败')
						this.num = 0;
					} else {
						this.getTaskStatus();
					}

				}, 2000)

			},


			async getTaskStatus() {
				let res = await this.$api.getTaskStatus({
					'task_number': this.task_number
				});
				console.log('res', res)
				if (res.data.is_print == 2) {
					clearTimeout(this.timer);
					this.printOk = true;
					this.loading.close();
					this.num = 0;
				}

			},


			//打印图片
			async printPic({
				print_type,
				type,
				print_number,
				print_file,
				paper_type
			}) {

				var printWorld = GetPrintWorld();


				console.log('图片预览', print_file);



				//  批量打印（Multiprint）JSON的action必须是print或者printfile。

				let json = {
					action: type, //预览打印
					copies: print_number,
					colorful: paper_type, //彩色
					format: "image_url", //打印形式
					/* paperwidth: "21", //尺寸
					paperlength: "29.7", */
					//	content: print_file, //内容
					colorful: paper_type, //颜色
					html: "https://print.ynlothar.com/mypreview/mypreview.htm"
				}



				if (this.type == "printfile") {
					json.callbackurl =
						`https://print.ynlothar.com/api/print_call_back/index?task_number=${this.task_number}&device_number=${this.device}`;
				}

				//  console.log('abc',`https://print.ynlothar.com/api/print_call_back/index?task_number=${this.task_number}&device_number=${device}`)

			




				if (this.model == 1) { //a4打印机
					json.printer = "EPSON WF-C5290 Series";

				} else {

					json.printer = "HiTi P525L";

					if (print_type == 2) {
						json.printer = "iR-ADV C5235";
					}


				}
				
				
				
				//6寸纸打印
				if (print_type == 7) {
					json.paperwidth = "10.2", //尺寸
						json.paperlength = "15.2"
						
						json.printer = "EPSON WF-C5290 Series2";
				} else {
					json.papersize = 9;
				}



				let pics = print_file.split(',');
				console.log('pics', pics)



				let arr = pics.map(item => {
					return this.$domain + item;
				})

				json.content = arr;



				console.log('jsons', json)
				//	return false;



				printWorld.Act(json);


			},

			//打印文件
			printFile({
				type,
				print_number,
				print_ctype,
				file_type,
				print_type,
				print_file,
				paper_type,
				print_typeset
			}) {



				var printWorld = GetPrintWorld();
				let json = {
					action: type, //预览打印
					copies: print_number, //数量
					colorful: paper_type, // 颜色
					content: print_file, //内容
					duplex: print_ctype, //1单面打印 2双面打印
					html: "https://print.ynlothar.com/mypreview/mypreview.htm"
				}





				


				if (file_type == "doc" || file_type == "docx") {

					json.format = "word_url";

				} else if (file_type == "pdf") {

					json.format = "pdf_url";

				} else if (file_type == 'xls' || file_type == 'xlsx') {

					json.format = "excel_url";


					//	json.papersize = 9;
					//    json.swap = true

				} else if (file_type == 'ppt' || file_type == 'pptx') {

					json.format = "ppt_url";

				}

				if (this.type == "printfile") {
					json.callbackurl =
						`https://print.ynlothar.com/api/print_call_back/index?task_number=${this.task_number}&device_number=${this.device}`;



					//	 json.papersize = 9;  //a4纸张

					if (print_type == 1) { //a3
						// json.papersize = 8;
					}


				} else {

					json.papersize = undefined;

				}


				//竖版
				if (print_typeset == 1) {

					json.paperwidth = "21"
					json.paperlength = "29.7"
					//  json.swap = false

				} else {

					json.paperwidth = "29.7"
					json.paperlength = "21"
					// json.swap = true

				}





				if (this.model == 1) {

					if (print_type == 1) { //a3
						this.$message.error('对不起，当前设备不支持A3纸打印');
						return false;
					}

					json.printer = "EPSON WF-C5290 Series";
				//	json.printer = "Canon MG2500 series Printer";


				} else {


					if (print_type == 1) { //a3

						json.paperwidth = "41.9"
						json.paperlength = "29.7"
						//	json.papersize = 8;  //a3纸张
						//   json.swap = true

					}


					json.printer = "iR-ADV C5235";
				}





				console.log('文档json', json)

				printWorld.Act(json);

			},



			//证件照
			async printZj({
				print_type,
				type,
				print_number
			}) {
				console.log(4444)

				var printWorld = GetPrintWorld();
				let paiban = "";

				switch (print_type + '') {
					case '3':
						paiban = "html2canvas1"
						break;
					case '4':
						paiban = "html2canvas2"
						break;
					case '5':
						paiban = "html2canvas3"
						break;
					case '6':
						paiban = "html2canvas4"
						break;

				}



				console.log(paiban)
				let base64 = await this.saveImage(paiban, '');


				//	 console.log('base64',base64)
				//   return false;

				let json = {
					action: type, //预览打印
					copies: print_number,
					colorful: "2", //彩色
					format: "file_base64", //打印形式
					paperwidth: "10.2", //尺寸
					paperlength: "15.2",
					content: base64, //内容
					html: "https://print.ynlothar.com/mypreview/mypreview.htm"

				}

				if (this.type == "printfile") {
					json.callbackurl =
						`https://print.ynlothar.com/api/print_call_back/index?task_number=${this.task_number}&device_number=${this.device}`;
				}



				if (this.model == 1) { //a4打印机
					json.printer = "EPSON WF-C5290 Series2";

				} else {
					json.printer = "HiTi P525L";
				}

				console.log('json', json)
				printWorld.Act(json);
			},


			dataURLToBlob(dataurl) {
				let arr = dataurl.split(',');
				let mime = arr[0].match(/:(.*?);/)[1];
				let bstr = atob(arr[1]);
				let n = bstr.length;
				let u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}


				return new Blob([u8arr], {
					type: mime
				});
			},
			//点击方法
			saveImage(divText, imgText) {
				return new Promise(resolve => {

					console.log(divText, imgText);

					let canvasID = this.$refs[divText];
					let that = this;
					let a = document.createElement('a');
					html2canvas(canvasID, {
						useCORS: true
					}).then(canvas => {
						let dom = document.body.appendChild(canvas);
						dom.style.display = 'none';
						a.style.display = 'none';
						document.body.removeChild(dom);



						let blob = that.dataURLToBlob(dom.toDataURL('image/png'));

						resolve(dom.toDataURL('image/png'));


					/* 	a.setAttribute('href', URL.createObjectURL(blob));
						//这块是保存图片操作  可以设置保存的图片的信息
						a.setAttribute('download', imgText + '.png');
						document.body.appendChild(a);
						a.click();
						URL.revokeObjectURL(blob);
						document.body.removeChild(a);
 */

					})
				})


			},



		}
	}
</script>

<style lang="scss">
	.el-loading-spinner {
		p {
			font-size: 50px !important;
			color: #fff !important;
		}

		i {
			font-size: 100px;
			color: #fff !important;
		}

	}
</style>
