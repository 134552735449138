import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/member',
		children: [{
			path: '/index',
			name: 'index',
			component: () => import('../views/Index.vue')
		},{
			path: '/demo',
			name: 'demo',
			component: () => import('../views/demo.vue')
		},{
			path: '/ScanCode',
			name: 'ScanCode',
			component: () => import('../views/member/ScanCode.vue')
		},
		,{
			path: '/member',
			name: 'member',
			component: () => import('../views/member/member.vue')
		},
		{
			path: '/PrintFile',
			name: 'PrintFile',
			component: () => import('../views/member/PrintFile.vue')
		},
		{
			path: '/Shop',
			name: 'Shop',
			component: () => import('../views/member/Shop.vue')
		},
		{
			path: '/MyCloud',
			name: 'MyCloud',
			component: () => import('../views/member/MyCloud.vue')
		},
		{
			path: '/Pdf',
			name: 'pdf',
			component: () => import('../views/member/Pdf.vue')
		},
		{
			path: '/CloudFile',
			name: 'CloudFile',
			component: () => import('../views/member/CloudFile.vue')
		},
		{
			path: '/Task',
			name: 'Task',
			component: () => import('../views/member/Task.vue')
		},
		{
			path: '/FileUpload/:type',
			name: 'FileUpload',
			component: () => import('../views/member/FileUpload.vue')
		},
		{
			path: '/Recharge',
			name: 'Recharge',
			component: () => import('../views/member/Recharge.vue')
		},
		{
			path: '/Print',
			name: 'Print',
			component: () => import('../views/Print.vue')
		},
		{
			path: '/Scanning',
			name: 'Scanning',
			component: () => import('../views/Scanning.vue')
		},
		{
			path: '/CardRead',
			name: 'CardRead',
			component: () => import('../views/CardRead.vue')
		},
		{
			path: '/select',
			name: 'select',
			component: () => import('../views/select.vue')
		},
		{
			path: '/ScanningFile',
			name: 'ScanningFile',
			component: () => import('../views/ScanningFile.vue')
		},
		{
			path: '/ScanningFilePdf',
			name: 'ScanningFilePdf',
			component: () => import('../views/ScanningFilePdf.vue')
		},
		{
			path: '/Tourists',
			name: 'Tourists',
			component: () => import('../views/tourists/Tourists.vue')
		},
		{
			path: '/RegisterVip',
			name: 'RegisterVip',
			component: () => import('../views/registerVip.vue')
		}
		
		
		]
	},

	{
		path: '/about',
		name: 'About',

		component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
	}
]

const router = new VueRouter({
	routes
})

//挂载路由守卫导航
router.beforeEach((to,from,next)=>{
	//to 要访问的路径
	// from 从哪个路径跳转
	// next() 函数 表示放行  next('/login') 强制跳转
	if(to.path === '/index' || to.path === '/Tourists' || to.path === '/member') return next();
	//获取token
	/* const  tokenStr = sessionStorage.getItem('token');
	if(!tokenStr) return next('/index'); */
	next();
})


export default router
