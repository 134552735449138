import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
/* import './plugins/element.js' */

import api from "./api/index.js"

Vue.prototype.$api = api;

Vue.prototype.$domain = "https://print.ynlothar.com"


import wsConnection from '@/store/wsStore'

//挂载vue原型链
Vue.prototype.$setWs = wsConnection;

//挂载公共方法
import common from '@/utils/common.js'
Vue.prototype.common = common;



import * as socketApi from '@/utils/websocket.js'
Vue.prototype.socketApi = socketApi

//字体图标
import './assets/css/iconfont.css'

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
