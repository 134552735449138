<template>
	<div class="home" style="" v-loading="loading" element-loading-text="正在打印" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.5)" customClass="myloading">
		<header>
			<div class="container">
				<i v-if="backshow" class="iconfont icon-fanhui" @click="back()"></i>
				<i v-if="backshow" class="el-icon-refresh" style="font-size: 30px; color:#fff; margin-left:45px"
					@click="refresh"></i>
				<Time></Time>

				<div class="top">
					<div class="left">请选择您需要进行的操作</div>
					<div class="right" v-if="$store.state.userinfo">尊敬的个人会员：{{ $store.state.userinfo.nick_name }}</div>
				</div>
			</div>
		</header>


		<Print ref="print"></Print>



		<div class="center">
			<router-view />
		</div>











		<el-dialog :visible.sync="printOk" width="30%">
			<div class="info" style="text-align: center;">
				<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
				<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>

				<div v-if="$store.state.userinfo">
					<p style="color:red; font-weight: bold; font-size: 16px; margin-bottom: 20px;">为确保您的信息安全，打印完成后记得退出
					</p>
					<p>
						<el-button type="danger" style="width:80%" @click="loginout()">退出</el-button>
					</p>
					<p>
						<el-button type="primary" style="width:80%; margin:30px 0" @click="printOk=false">继续打印
						</el-button>
					</p>
				</div>
			</div>
		</el-dialog>



		<footer>
			<div class="container">
				<el-row>
					<el-col :span="8">
						<div class="codeText">
							<div>
								<img :src="code" alt="" width="100px">
							</div>
							<div class="info">
								<div>扫码使用微信小程序自助打印</div>
								<div>青岛坤源信息科技有限公司</div>
							</div>
						</div>

					</el-col>


					<el-col :span="8">
						<div class="info">
							<div>服务热线：400-000-0001</div>
							<div>终端编号：{{ device }}</div>
							<div v-if="model == '1'">此设备支持a4纸及照片纸打印</div>
							<div v-if="model == '2'">此设备支持a3、a4纸及高光泽照片纸打印</div>

						</div>
					</el-col>
					<el-col :span="8">
						<div class="info3">
							<div>A3纸 {{ paper.paper_remain_A3 }}张</div>
							<div>A4纸 {{ paper.paper_remain_A4  }}张</div>
							<div>照片纸 {{ paper.paper_remain_photo }} 张</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</footer>

	</div>
</template>

<script>
	// @ is an alias to /src
	import Time from '@/components/time.vue'

	import Print from "@/components/Print.vue"

	/* import {
		getLodop
	} from '../utils/LodopFuncs'
 */
	export default {
		name: 'Home',
		components: {
			Time,
			Print
		},
		data() {
			return {
				backshow: true,
				paper: {}, //纸张
				list: [],
				printOk: false,
				loading: false,
				device: '尚未绑定设备',
				code: '',

				ws: null, //建立的连接
				lockReconnect: false, //是否真正建立连接
				timeout: 5 * 1000, //58秒一次心跳
				timeoutObj: null, //心跳心跳倒计时
				serverTimeoutObj: null, //心跳倒计时
				timeoutnum: null, //断开 重连倒计时
				dialogVisible: false,
				url: '',
				pic: 'https://print.ynlothar.com/uploads/20220414/649fb02e1b43c5d2297bdb72d3e4be2bbc04d7b0.png',
				size: "",

				typeset: '1', //1竖版  2横版

				jobCode: '1', //job码
				printStatus: 1, //打印状态
				LODOP: null,
				model: 0,
			}
		},

		created() {


			if (localStorage.getItem('device')) {
				this.PcHome(); //获取纸张
				this.device = localStorage.getItem('device')
				this.model = localStorage.getItem('model');
			} else {
				this.$message.warning('您尚未绑定设备编号')
			}


			console.log('路由c', this.$router.history.current.name)
			if (this.$router.history.current.name == 'FileUpload') {
				this.initWebpack()
			}

			setTimeout(() => {
				this.$store.commit('setDevList', getLodop());
			}, 1000)

		},
		mounted() {

			document.oncontextmenu = function() {
				return false;
			};

			//	this.open()
			if (!localStorage.getItem('index_code')) {
				this.weChatCode()
			} else {
				this.code = this.$domain + localStorage.getItem('index_code');
			}

		},
		methods: {

			demo() {

			},

			async weChatCode() {
				if (localStorage.getItem('device')) {
					let scene = localStorage.getItem('device') + '?' + localStorage.getItem('model') +
						'?index';



					console.log('abc', scene)
					this.model = localStorage.getItem('model');
					//return false
					let data = {
						type: '1',
						scene: scene,
						ctype: '1',

					}
					console.log('参数', data);

					let res = await this.$api.weChatCode(data)
					if (res.code == '200') {
						this.code = res.data
						localStorage.setItem('index_code', this.code)
					}
				} else {
					this.$message.error('请先绑定设备编号')

				}


			},



			back() {
				this.$router.back();
			},
			refresh() {
				this.$router.go(0)
			},

			//退出登录
			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.printOk = false;
				this.$router.push('/');
			},

			async PcHome() {
				console.log('纸张')
				/* 	try { */
				let res = await this.$api.PcHome({
					'number': localStorage.getItem('device')
				});
				if (res.code == 200) {
					if(res.data){
						this.paper = res.data; //不存在纸张说嘛编号过期
					}else{
						
						localStorage.removeItem('device') //设备编号
						localStorage.removeItem('code')
						localStorage.removeItem('pic_code')
						localStorage.removeItem('file_code')
						localStorage.removeItem('zjz_code')
						localStorage.removeItem('index_code')
					}
					
					
				}

			},
			
			async OrderDetail(data) {

				try {
					let res = await this.$api.OrderDetail(data);
					if (res.code == 200) {

						this.$refs.print.order(res.data, 'printfile')
					}


				} catch (err) {
					this.$message.error('数据请求失败');
				}
			},

			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},





			open() {
				this.printOk = true;

				setTimeout(() => {
					this.printOk = false;
				}, 3000)



			},







			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				console.log(111);
				//this.loading = false;
				//this.TaskList();
			},



		

			initWebpack() {
				console.log('abcd');
				const wsurl = 'wss://print.ynlothar.com/wss'; //ws地址,这里加入自己的地址即可
				this.ws = new WebSocket(wsurl);
				this.ws.onopen = this.onopen;
				this.ws.onmessage = this.onmessage;
				this.ws.onclose = this.onclose;
				this.ws.onerror = this.onerror;


			},
			reconnect() { //重新连接
				var that = this;
				if (that.lockReconnect) {
					return;
				};
				that.lockReconnect = true;
				//没连接上会一直重连，设置延迟避免请求过多
				that.timeoutnum && clearTimeout(that.timeoutnum);
				that.timeoutnum = setTimeout(function() {
					//新连接
					that.initWebpack();
					that.lockReconnect = false;
				}, 5000);
			},
			reset() { //重置心跳
				var that = this;
				//清除时间
				clearTimeout(that.timeoutObj);
				clearTimeout(that.serverTimeoutObj);
				//重启心跳
				that.start();
			},
			start() { //开启心跳
				console.log('开启心跳');
				var self = this;
				self.timeoutObj && clearTimeout(self.timeoutObj);
				self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
				self.timeoutObj = setTimeout(function() {
					//这里发送一个心跳，后端收到后，返回一个心跳消息，

					var data = {
						'uid': 0,
						'msg': 'ping',
						'type': 2
					};

					self.ws.send(JSON.stringify(data)); //这里可以自己跟后端约定

					self.serverTimeoutObj = setTimeout(function() {
						//超时关闭
						self.ws.close();
					}, self.timeout);
				}, self.timeout)
			},
			onopen() {
				console.log("open");
				let data = {
					'uid': 'aaa',
					'msg': 222,
					'type': 1,
					'number': localStorage.getItem('device'),
					'ctype': 'pc',
					'genre': 2
				};
				this.ws.send(JSON.stringify(data));
				//开启心跳
				this.start();
			},
			onmessage(e) {
				console.log('接收信息', e);

				let data = e.data;
				if (!data.indexOf('ping') == 0 && !data.indexOf('type') == 0) {

					data = JSON.parse(data);

					if (data.name == "print") {
						console.log('立即打印', data.order_number)
						//alert()
						let param = {
							'uid': data.uid,
							'number': data.order_number
						}
						console.log('参数', param)

						this.typeset = data.typeset;

						this.OrderDetail(param)
					}


					if (data.name == "preview") {
						console.log('预览-----', data)
						this.$refs.print.order(data.url, 'previewfile')
					}

				}

				this.reset();
			},
			onclose(e) {
				console.log("连接关闭", this.$router.history.current.name);
				console.log('websocket 断开1: ' + e.code + ' ' + e.reason + ' ' + e.wasClean);
				//重连

				if (this.$router.history.current.name == 'FileUpload') {
					this.reconnect();
				}

			},
			onerror(e) {
				console.log("出现错误");

				if (this.$router.history.current.name == 'FileUpload') {
					//重连
					this.reconnect();
				}


			},
			onsend(msg) { //向服务器发送信息
				//数据发送
				this.websock.send(msg);
			},

		},
		watch: {
			$route(to, from) {
				//	

				if (to.name == 'FileUpload') {
					this.initWebpack()
				} else {
					//this.$router.go(0)
					/* this.ws = null;
					clearTimeout(this.timeoutnum) 
					clearTimeout(this.serverTimeoutObj);
					clearTimeout(this.serverTimeoutObj); */
					this.ws.close()

				}

				console.log('to', to)
				//
			},

			LODOP(newObj, oldObj) {
				//console.log('333',this.LODOP)
				//console.log('new',newObj)
				//console.log('old',oldObj)
			}

		},
	}
</script>


<style scoped lang="scss">
	header {
		height: 10vh;

	}

	.center {
		min-height: 73vh;
		height: auto;
		margin: 1vh 0;


		background-color: #259DDC;
	}

	.home {
		height: 100%;
		background-color: #259DDC;

		.container {
			padding: 15px;
		}


	}

	.myloading {
		color: #fff;
	}

	::v-deep .el-loading-spinner {
		p {
			font-size: 50px;
			color: #fff;
		}

		i {
			font-size: 100px;
			color: #fff;
		}

	}

	.icon-fanhui {
		font-size: 30px !important;
		color: #fff;
		cursor: pointer;

	}

	.time {
		float: right;
	}


	.photo {
		margin-top: 15px;
	}

	.photobox {
		display: none;
	}


	.top {
		margin-top: 15px;
		height: 30px;
		color: #fff;
	}


	.left {
		float: left;
	}

	.right {
		float: right;
	}



	footer {
		background-color: #3689b4;
		height: 15vh;
		width: 100%;
		position: fixed;
		bottom: 0px;
		color: #fff;
		padding-top: 1vh;
		text-align: center;

		.codeText {
			display: flex;

			.iconfont {
				font-size: 80px;
			}

		}

		.info {
			margin-left: 15px;
			line-height: 30px;
		}

		.info3 {
			margin-left: 15px;
			line-height: 30px;
		}

	}
</style>
