function voice(text) {
	console.log(8888)
	var msg = new SpeechSynthesisUtterance();
	msg.rate = 0.8; //播放语速
	msg.pitch = 1; //音调高低
	msg.text = text;
	msg.volume = 10; //播放音量
	msg.lang = "zh-CN"; //语言类型
	speechSynthesis.speak(msg); //播放
}

export default {
	voice
}
